<template>
	<screen class="reports-view" size="large">
		<h1 slot="title">General Reports</h1>
		<section class="report-panels">
			<router-link
				:to="{ name: 'reports.donations' }"
				class="panel panel-donation"
			>
				<h3>Forecasted Donation</h3>
				<div class="icon icon-coin"></div>
				<div class="value">
					<strong>{{ parseForecastedDonation() }}</strong>
					<span>accumulated RoundUps this month</span>
				</div>
				<div class="panel-footer">
					<div class="subscript">
						<strong>{{ numberFormat(general.donatedYTD) }}</strong>
						donated YTD
					</div>
					<ui-button>View Report</ui-button>
				</div>
			</router-link>
			<router-link :to="{ name: 'reports.transactions' }" class="panel">
				<h3>New RoundUps</h3>
				<div class="icon icon-roundup"></div>
				<div class="value">
					<strong>{{
						general.newRoundUps > 0
							? '+' + commaFormat(general.newRoundUps)
							: 0
					}}</strong>
					<span>roundUps this week</span>
				</div>
				<div class="panel-footer">
					<div class="subscript">
						<strong>{{
							commaFormat(general.totalRoundUps)
						}}</strong>
						total RoundUps
					</div>
					<ui-button>View Report</ui-button>
				</div>
			</router-link>
			<a @click.prevent="handleMonthlyDownload" class="panel">
				<h3>Monthly Report</h3>
				<div class="value">
					<strong>{{ getLastMonth() }}</strong>
				</div>
				<div class="panel-footer">
					<ui-button>Download</ui-button>
				</div>
			</a>
			<div class="panel">
				<h3>Yearly Report</h3>
				<ui-label
					:style="{
						textAlign: 'center',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '20px'
					}"
				>
					<select
						class="value select-year"
						v-model="year"
						:style="{
							textAlign: 'center',
							fontSize: '48px',
							padding: '0px 25px',
							color: '#71788C',
							fontWeight: 900,
							borderWidth: '1px'
						}"
					>
						<option
							v-for="(yr, idx) in getYears()"
							:key="idx"
							:style="{
								display: 'flex',
								alignContent: 'center',
								alignItems: 'center',
								justifyContent: 'center'
							}"
						>
							{{ yr }}
						</option>
						<i class="fas fa-chevron-down"></i>
					</select>
				</ui-label>
				<a class="panel-footer" @click.prevent="handleYearlyDownload">
					<ui-button>Download</ui-button>
				</a>
			</div>
		</section>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { numberFormat, getLastMonth, commaFormat } from '../../../utilities'
import download from '@/mixins/download'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'

export default {
	name: 'reports-view',
	components: { Screen, UiButton, UiLabel },
	mixins: [download],
	data() {
		return {
			donatedYTD: '$15,345.21',
			pendingDeposit: '$455.32',
			nextDeposit: '08/31/2018',
			newRoundUps: 0,
			totalRoundUps: 124,
			year: new Date().getFullYear()
		}
	},
	computed: {
		...mapState({
			general: ({ reportList }) => reportList.general,
			authToken: ({ me }) => me.token
		})
	},
	methods: {
		...mapActions(['getGeneralReport']),
		handleMonthlyDownload() {
			window.analytics.track('download_monthly_report')
			this.postParameters(
				'/reports/last-month-summary/csv',
				{},
				this.authToken
			)
		},
		handleYearlyDownload() {
			window.analytics.track('download_annual_report')
			this.postParameters(
				'/reports/last-year-summary/csv?year=' + this.year,
				{},
				this.authToken
			)
		},
		numberFormat,
		commaFormat,
		getLastMonth,
		getYears() {
			if (
				this.general.donatedYears &&
				this.general.donatedYears.length > 0
			) {
				this.year = this.general.donatedYears[0]
				return this.general.donatedYears
			}
			return [this.year]
		},
		parseForecastedDonation() {
			if (
				this.general.pendingDeposit &&
				this.general.pendingDeposit.length > 0 &&
				this.general.pendingDeposit[0].expected_donation
			) {
				return this.numberFormat(
					this.general.pendingDeposit[0].expected_donation
				)
			}
			return 0
		}
	},
	mounted() {
		this.getGeneralReport()
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.report-panels {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 20px;
	min-height: 540px;
	height: 100%;
	.panel {
		border: 1px solid $roundup-light-grey;
		border-radius: 20px;
		position: relative;
		display: block;
		text-align: center;
		padding: 20px 20px 100px 20px;
		text-decoration: none;
		color: $roundup-medium-grey;
		&:hover {
			background: $roundup-lightest-grey;
		}
		h3 {
			color: $roundup-medium-grey;
		}
		.value {
			font-size: 48px;
			span {
				display: block;
				font-size: 14px;
			}
		}
		.icon {
			display: inline-block;
			margin-top: 30px;
			margin-bottom: 30px;
			background-size: 100% auto;
			&.icon-coin {
				background-image: url('../../../assets/taxDeductible.png');
				width: 55px;
				height: 49px;
			}
			&.icon-roundup {
				background-image: url('../../../assets/raised_icon.png');
				width: 55.4px;
				height: 53px;
			}
			&.icon-eye {
				background-image: url('../../../assets/eye.png');
				width: 74px;
				height: 65px;
			}
		}
		.select-year {
			border: 4px solid $roundup-navy;
			border-radius: 4px;
			cursor: pointer;
		}
		.panel-footer {
			position: absolute;
			bottom: 20px;
			left: 0px;
			width: 100%;
			span {
				display: block;
			}
		}
		&.panel-donation {
			.value {
				strong {
					color: $roundup-light-green;
				}
			}
		}
	}
}
</style>
