var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen',{staticClass:"reports-view",attrs:{"size":"large"}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("General Reports")]),_c('section',{staticClass:"report-panels"},[_c('router-link',{staticClass:"panel panel-donation",attrs:{"to":{ name: 'reports.donations' }}},[_c('h3',[_vm._v("Forecasted Donation")]),_c('div',{staticClass:"icon icon-coin"}),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.parseForecastedDonation()))]),_c('span',[_vm._v("accumulated RoundUps this month")])]),_c('div',{staticClass:"panel-footer"},[_c('div',{staticClass:"subscript"},[_c('strong',[_vm._v(_vm._s(_vm.numberFormat(_vm.general.donatedYTD)))]),_vm._v(" donated YTD ")]),_c('ui-button',[_vm._v("View Report")])],1)]),_c('router-link',{staticClass:"panel",attrs:{"to":{ name: 'reports.transactions' }}},[_c('h3',[_vm._v("New RoundUps")]),_c('div',{staticClass:"icon icon-roundup"}),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.general.newRoundUps > 0 ? '+' + _vm.commaFormat(_vm.general.newRoundUps) : 0))]),_c('span',[_vm._v("roundUps this week")])]),_c('div',{staticClass:"panel-footer"},[_c('div',{staticClass:"subscript"},[_c('strong',[_vm._v(_vm._s(_vm.commaFormat(_vm.general.totalRoundUps)))]),_vm._v(" total RoundUps ")]),_c('ui-button',[_vm._v("View Report")])],1)]),_c('a',{staticClass:"panel",on:{"click":function($event){$event.preventDefault();return _vm.handleMonthlyDownload.apply(null, arguments)}}},[_c('h3',[_vm._v("Monthly Report")]),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.getLastMonth()))])]),_c('div',{staticClass:"panel-footer"},[_c('ui-button',[_vm._v("Download")])],1)]),_c('div',{staticClass:"panel"},[_c('h3',[_vm._v("Yearly Report")]),_c('ui-label',{style:({
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '20px'
				})},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.year),expression:"year"}],staticClass:"value select-year",style:({
						textAlign: 'center',
						fontSize: '48px',
						padding: '0px 25px',
						color: '#71788C',
						fontWeight: 900,
						borderWidth: '1px'
					}),on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.year=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_vm._l((_vm.getYears()),function(yr,idx){return _c('option',{key:idx,style:({
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							justifyContent: 'center'
						})},[_vm._v(" "+_vm._s(yr)+" ")])}),_c('i',{staticClass:"fas fa-chevron-down"})],2)]),_c('a',{staticClass:"panel-footer",on:{"click":function($event){$event.preventDefault();return _vm.handleYearlyDownload.apply(null, arguments)}}},[_c('ui-button',[_vm._v("Download")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }